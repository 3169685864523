import clsx from 'classnames'
import * as React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppContext } from '@components/AppContext'
import { CompanyHeaderLinkWrapper } from '@components/CompanyHeaderLinkWrapper'
import { Logo } from '@components/Logo'
import { basicSelector, getHomepageLink } from '../helpers'
import Container from './Container'
import styles from './Header.module.scss'
import SelectLanguage from './SelectLanguage'
import Stepper from './Stepper'

const Header: React.FunctionComponent<React.PropsWithChildren> = () => {
  const { language } = useAppContext()
  const { isDataFilled, step } = useSelector(basicSelector)

  useEffect(() => {
    if (step === 1) return

    const onBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDataFilled) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataFilled])

  return (
    <header
      className={clsx(styles.root, {
        [styles.rootShadow]: step > 1 && step < 5,
      })}
    >
      <Container>
        <div className={styles.wrapper}>
          <CompanyHeaderLinkWrapper link={getHomepageLink(language)}>
            <Logo />
          </CompanyHeaderLinkWrapper>
          {step < 6 && <Stepper />}
          <SelectLanguage />
        </div>
      </Container>
    </header>
  )
}

export default Header
