import Head from 'next/head'
import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAppContext } from '@components/AppContext'
import { ILanguage } from '@interfaces/data'
import { setLanguageInitialized } from '@redux/actions'
import * as selectors from '@redux/selectors'
import { getLocalStorageValue, SEGMENT_KEYS } from '@utils/localStorage'
import { getLangIso } from '../helpers'
import { useQueryParams } from '../hooks/useQueryParams'
import Content from './Content'
import Footer from './Footer'
import Header from './Header'

type LayoutProps = {
  title?: string
}

const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  children,
  title = 'Spa Booking',
}) => {
  const dispatch = useDispatch()
  const { language, onChangeLanguage } = useAppContext()
  const { routerQueryParams } = useQueryParams()
  const languageInitialized = useSelector(selectors.getLanguageInitialized)

  // init language with initialization of app
  useEffect(() => {
    if (languageInitialized) return

    const routeQueryLang = routerQueryParams.lang ? getLangIso(routerQueryParams.lang) : null
    const lang = routeQueryLang || getLocalStorageValue(SEGMENT_KEYS.lang) || language
    onChangeLanguage(lang as ILanguage, routerQueryParams)
    dispatch(setLanguageInitialized(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport" />
        <link href="/favicon.svg" rel="shortcut icon" />
      </Head>
      <>
        <Header />
        <Content>{children}</Content>
        <Footer />
      </>
    </>
  )
}

export default Layout
