import axios from 'axios'
import { logToSentry } from '@utils/sentry'

const languages = ['cs_CZ', 'de', 'ru', 'en']

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Accept: 'application/json',
    'Accept-Language': languages.join(', '),
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_TOKEN}`,
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use((config) => {
  logToSentry(`calling API end point ${config.baseURL}`, config)
  return config
})

export default instance
