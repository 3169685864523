import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { FooterBackgroundWrapper } from '@components/FooterBackgroundWrapper'
import * as selectors from '@redux/selectors'
import {
  getContactLink,
  getCopyright,
  getPersonalDataProcessingLink,
  getTermsAndConditionsLink,
} from '../helpers'
import { useAppContext } from './AppContext'
import Container from './Container'
import styles from './Footer.module.scss'
import Typography from './Typography'

const Footer: React.FunctionComponent<React.PropsWithChildren> = () => {
  const { language } = useAppContext()
  const languageInitialized = useSelector(selectors.getLanguageInitialized)
  const step = useSelector(selectors.getStep)

  const showFooterBackground = step === 1

  return (
    <>
      {languageInitialized && (
        <FooterBackgroundWrapper footerWithBackground={showFooterBackground}>
          <Container component="footer">
            <div className={styles.rootWrapper}>
              <Typography className={styles.rootCopyright} component="span" variant="caption">
                {new Date().getFullYear()} &copy; {getCopyright()}
              </Typography>
              <nav className={styles.rootNavigation}>
                <a
                  href={getTermsAndConditionsLink(language)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage
                    defaultMessage="General Terms & Conditions"
                    description="Footer link for General Terms and Conditions"
                  />
                </a>
                <a
                  href={getPersonalDataProcessingLink(language)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage
                    defaultMessage="Privacy Policy"
                    description="Footer link for Privacy policy"
                  />
                </a>
                <a href={getContactLink(language)} rel="noopener noreferrer" target="_blank">
                  <FormattedMessage
                    defaultMessage="Contact"
                    description="Footer link for Contact"
                  />
                </a>
              </nav>
            </div>
          </Container>
        </FooterBackgroundWrapper>
      )}
    </>
  )
}

export default Footer
