/*
 * If the destination is specified in the .env file, the booking form will focus on a single destination (its hotels, types of stays, etc.).
 * If no destination is specified, the booking form will display offers, hotels, and more for all available destinations within SpaBooking.
 */
import { IDestination } from '@interfaces/data'
import { getLayoutConfig } from '../config'

export const getDefaultDestinationId = (): number | null => {
  const config = getLayoutConfig()

  const destinationId = config.destinationId

  if (destinationId && Number.isFinite(destinationId)) {
    return Number(destinationId)
  }

  return null
}

export const getDestinationById = (
  destinationId: number | string,
  destinationsData: IDestination[]
) => {
  const destination = destinationsData.find(
    (destination) => Number(destination.id) === Number(destinationId)
  )

  return destination ?? null
}
