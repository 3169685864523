import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { ILang, ILanguage, IRouteQueryParams } from '@interfaces/data'
import { useQueryState } from 'nuqs'

interface QueryParams {
  addQueryParameter: (param: string, value: string | null | undefined) => void
  removeQueryParameter: (param: keyof IRouteQueryParams) => void
  removeQueryParametersArray: (params: Array<keyof IRouteQueryParams>) => void
  routerQueryParams: IRouteQueryParams
}

export const GUEST_PARAM = 'guest'
export const HOTEL_PARAM = 'hotel'
export const LANG_PARAM = 'lang'
export const ROOM_TYPE_PARAM = 'roomType'
export const VISIT_START_PARAM = 'visitStart'
export const VISIT_END_PARAM = 'visitEnd'
export const VISIT_TYPE_PARAM = 'visitType'
export const AFFIL_USER_NAME = 'affilUserName'
export const DESTINATION_PARAM = 'destination'

export const stateQueryParams = [
  GUEST_PARAM,
  HOTEL_PARAM,
  LANG_PARAM,
  ROOM_TYPE_PARAM,
  VISIT_START_PARAM,
  VISIT_END_PARAM,
  VISIT_TYPE_PARAM,
  AFFIL_USER_NAME,
  DESTINATION_PARAM,
]

export const useQueryParams = (): QueryParams => {
  const router = useRouter()

  const [guest, setGuest] = useQueryState(GUEST_PARAM)
  const [hotel, setHotel] = useQueryState(HOTEL_PARAM)
  const [lang, setLang] = useQueryState(LANG_PARAM)
  const [roomType, setRoomType] = useQueryState(ROOM_TYPE_PARAM)
  const [visitStart, setVisitStart] = useQueryState(VISIT_START_PARAM)
  const [visitEnd, setVisitEnd] = useQueryState(VISIT_END_PARAM)
  const [visitType, setVisitType] = useQueryState(VISIT_TYPE_PARAM)
  const [affilUserName, setAffilUserName] = useQueryState(AFFIL_USER_NAME)
  const [destination, setDestination] = useQueryState(DESTINATION_PARAM)

  // Capture other dynamic parameters
  const dynamicParams = Object.fromEntries(
    Object.entries(router.query).filter(
      ([key]: [string, string | string[] | undefined]) => !stateQueryParams.includes(key)
    )
  )

  const setQueryParameterValueByName = (param: string, value: string | null | undefined) => {
    if (stateQueryParams.includes(param)) {
      const valueToSet = value ?? null

      switch (param) {
        case GUEST_PARAM:
          setGuest(valueToSet)
          break
        case HOTEL_PARAM:
          setHotel(valueToSet)
          break
        case LANG_PARAM:
          setLang(valueToSet)
          break
        case ROOM_TYPE_PARAM:
          setRoomType(valueToSet)
          break
        case VISIT_START_PARAM:
          setVisitStart(valueToSet)
          break
        case VISIT_END_PARAM:
          setVisitEnd(valueToSet)
          break
        case VISIT_TYPE_PARAM:
          setVisitType(valueToSet)
          break
        case AFFIL_USER_NAME:
          setAffilUserName(valueToSet)
          break
        case DESTINATION_PARAM: {
          setDestination(valueToSet)
          break
        }
        default:
      }
    } else {
      // Dynamic parameters are added or removed directly via router.push
      const updatedQuery = { ...router.query, ...dynamicParams, [param]: value }
      if (value === null) delete updatedQuery[param]

      router.push(
        {
          pathname: router.pathname,
          query: updatedQuery,
        },
        undefined,
        { shallow: true }
      )
    }
  }

  const addQueryParameter = useCallback((param: string, value: string | null | undefined) => {
    setQueryParameterValueByName(param, value)
  }, [])

  const removeQueryParameter = useCallback((param: keyof IRouteQueryParams) => {
    setQueryParameterValueByName(param, null)
  }, [])

  const removeQueryParametersArray = useCallback((params: Array<keyof IRouteQueryParams>) => {
    params.forEach((param) => {
      removeQueryParameter(param)
    })
  }, [])

  const routerQueryParams = {
    affilUserName,
    destination,
    guest,
    hotel,
    lang: lang as keyof ILang | ILanguage | null,
    roomType,
    visitEnd,
    visitStart,
    visitType,
  }

  return {
    addQueryParameter,
    removeQueryParameter,
    removeQueryParametersArray,
    routerQueryParams: {
      ...routerQueryParams,
      ...dynamicParams,
    },
  }
}
