import { createWrapper, MakeStore } from 'next-redux-wrapper'
import {
  applyMiddleware,
  legacy_createStore as createStore,
  Middleware,
  Store,
  StoreEnhancer,
} from 'redux'
import { createTransform, persistReducer, persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'
import createSagaMiddleware from 'redux-saga'
import { AppState } from '@interfaces/data'
import { IAction } from '@redux/interfaces'
import rootReducer from './reducer'
import rootSaga from './saga'

const MAX_DAYS = process.env.NEXT_PUBLIC_AFFILIATE_EXPIRE_DAYS
  ? parseInt(process.env.NEXT_PUBLIC_AFFILIATE_EXPIRE_DAYS)
  : 30
const PERSIST_EXPIRE_DEFAULT_KEY = 'persistExpiresAt'
const PERSIST_MAX_AGE = MAX_DAYS * 24 * 60 * 60 * 1000

const createNoopStorage = () => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getItem(_key: any) {
      return Promise.resolve(null)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeItem(_key: any) {
      return Promise.resolve()
    },
    setItem(_key: any, value: any) {
      return Promise.resolve(value)
    },
  }
}

// Transformation to add expiration when saving state
const setExpiration = (state: any) => {
  const expireAt = Date.now() + PERSIST_MAX_AGE
  return {
    ...state,
    [PERSIST_EXPIRE_DEFAULT_KEY]: expireAt,
  }
}

const validateExpiration = (state: any) => {
  if (!state || !state[PERSIST_EXPIRE_DEFAULT_KEY]) return state

  const now = Date.now()
  const expireAt = state[PERSIST_EXPIRE_DEFAULT_KEY]

  if (now >= expireAt) {
    return {
      affilUserName: null,
    }
  }

  return state
}

const persistConfig = {
  key: 'root',
  storage: typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage(),
  transforms: [
    createTransform(
      (inboundState) => setExpiration(inboundState),
      (outboundState) => validateExpiration(outboundState)
    ),
  ],
  whitelist: ['affiliate'],
}

const bindMiddleware = (middleware: Middleware[]): StoreEnhancer => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('@redux-devtools/extension')
    const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 5 })
    return composeEnhancers(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

let store: Store<AppState, IAction>
let persistor: Persistor

const makeStore: MakeStore<Store<AppState, IAction>> = () => {
  const sagaMiddleware = createSagaMiddleware()
  const persistedReducer = persistReducer(persistConfig, rootReducer)
  store = createStore(persistedReducer, bindMiddleware([sagaMiddleware]))
  persistor = persistStore(store)

  store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

const wrapper = createWrapper(makeStore)

export { makeStore, persistor, store, wrapper }
