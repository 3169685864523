import { AxiosError, AxiosResponse } from 'axios'
import { format, isDate } from 'date-fns'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { alertSentryOnAPIError, customizeError, MESSAGE_LEVELS } from '@utils/sentry'
import { DATE_FORMAT_YYYY_MM_DD } from '../constants'
import {
  getBookingConfigDestinationQueryParameter,
  getCalendarDates,
  getDateKeysForCache,
  getDatesForCalendarDate,
  getLang,
  getLangIso,
  getRangeForCalendarDatesRequest,
  getValidDestinationOrNull,
  getValidGuestNumberOrNull,
  isGuestDefined,
  parseDateString,
  PathEnum,
  redirectTo,
  sanitizeBookingParams,
} from '../helpers'
import {
  addNotification,
  changeCalendarDate,
  loadCalendarPriceFailure,
  loadCalendarPriceSuccess,
  loadDatesCheckoutFailure,
  loadDatesCheckoutSuccess,
  loadDatesFailure,
  loadDatesSuccess,
  loadDestinationsFailure,
  loadDestinationsSuccess,
  loadHotelFailure,
  loadHotelsFailure,
  loadHotelsSuccess,
  loadHotelSuccess,
  loadInitDataFailure,
  loadInitDataSuccess,
  loadLowestPriceFailure,
  loadLowestPriceSuccess,
  loadLoyaltyProgramFailure,
  loadLoyaltyProgramSuccess,
  loadPriceFailure,
  loadPriceSuccess,
  loadVisitCategoriesFailure,
  loadVisitCategoriesSuccess,
  resetCalendar,
  sendBookingFailure,
  sendBookingSuccess,
  setAppInitialized,
  setLoader,
  setOnlyAvailableDemand,
  setRouteQueryParams,
} from './actions'
import {
  getBookingConfiguration,
  getDates,
  getDatesCheckout,
  getDestinations,
  getHotel,
  getHotels,
  getLowestPrice,
  getLoyaltyProgram,
  getPrice,
  getVisitCategories,
  sendBooking,
} from './api'
import {
  ActionType,
  GuestFormData,
  GuestsFormData,
  IAction,
  IBookingRequest,
  IBookingResponse,
  ICurrency,
  IDate,
  IDateCheckout,
  IDatesCheckoutParamsQuery,
  IDatesParamsQuery,
  Identificator,
  IDestination,
  IGuest,
  IHotel,
  IHotelParamsQuery,
  IInitDataResponse,
  ILanguage,
  ILoyaltyProgram,
  IPrice,
  IRoomType,
  IRouteQueryParams,
  ITravelDate,
  IVisitCategory,
  IVisitType,
  IVisitTypeCategoryParamsQuery,
  Loaders,
  PersonalFormData,
  ServiceFormData,
  SummaryFormData,
} from './interfaces'
import * as selectors from './selectors'

function* loadDatesRequest(parameters: any) {
  try {
    const params = parameters.payload as IDatesParamsQuery

    let {
      dateMiddleCalendarDateCacheKey: dateMiddleCacheKey,
      dateToCalendarDateCacheKey: dateToCacheKey,
      ...restParams
    } = params

    let dateFrom = restParams?.date_from ?? undefined

    const { data, status }: AxiosResponse<IDate[]> = yield call(getDates, {
      ...restParams,
      date_from: !restParams.initial ? restParams.date_from : undefined,
      date_to: !restParams.initial ? restParams.date_to : undefined,
      initial: undefined,
    })

    let calendarDates: IDate[] = []
    let calendarDateToSet: Date

    if (status === 200) {
      if (restParams.initial) {
        calendarDates = getCalendarDates(data)

        const [initialCalendarDate] = calendarDates

        calendarDateToSet = initialCalendarDate ? new Date(initialCalendarDate.date) : new Date()

        const { dateFromCalendarDate, dateMiddleCalendarDate, dateToCalendarDate } =
          getRangeForCalendarDatesRequest(calendarDateToSet, [], true)

        const {
          dateFromCalendarDateRequest,
          dateMiddleCalendarDateCacheKey,
          dateToCalendarDateCacheKey,
        } = getDateKeysForCache(dateFromCalendarDate, dateMiddleCalendarDate, dateToCalendarDate)

        dateFrom = dateFromCalendarDateRequest
        dateMiddleCacheKey = dateMiddleCalendarDateCacheKey
        dateToCacheKey = dateToCalendarDateCacheKey

        yield call(validateCalendarDatesRequest, {
          payload: {
            allAvailableDates: calendarDates,
            initial: true,
          },
          type: ActionType.VALIDATE_CALENDAR,
        })
      }

      yield put(
        loadDatesSuccess({
          dates: data,
          datesCache: {
            [dateFrom as string]: dateFrom ? getDatesForCalendarDate(dateFrom, data) : [],
            [dateMiddleCacheKey as string]: dateMiddleCacheKey
              ? getDatesForCalendarDate(dateMiddleCacheKey, data)
              : [],
            [dateToCacheKey as string]: dateToCacheKey
              ? getDatesForCalendarDate(dateToCacheKey, data)
              : [],
          },
        })
      )

      if (restParams.initial) {
        const [initialCalendarDate] = calendarDates

        const calendarDateToSet = initialCalendarDate
          ? new Date(initialCalendarDate.date)
          : new Date()

        yield put(changeCalendarDate(calendarDateToSet))
      }
    }
  } catch (err) {
    yield put(loadDatesFailure(err as Error))
  }
}

function* loadDatesCheckoutRequest(parameters: any) {
  try {
    const { data, status }: AxiosResponse<IDateCheckout[]> = yield call(
      getDatesCheckout,
      parameters.payload as IDatesCheckoutParamsQuery
    )
    if (status === 200) {
      yield put(loadDatesCheckoutSuccess(data))
    }
  } catch (err) {
    yield put(loadDatesCheckoutFailure(err as Error))
  }
}

function* loadHotelRequest(parameters: any) {
  try {
    const { data, status }: AxiosResponse<IHotel> = yield call(
      getHotel,
      parameters.payload as Identificator
    )
    if (status === 200) {
      yield put(loadHotelSuccess(data))
    }
  } catch (err) {
    yield put(loadHotelFailure(err as Error))
  }
}

function* loadHotelsRequest(parameters: any) {
  try {
    const { data, status }: AxiosResponse<IHotel[]> = yield call(
      getHotels,
      parameters.payload as IHotelParamsQuery
    )
    if (status === 200) {
      yield put(loadHotelsSuccess(data))
    }
  } catch (err) {
    yield put(loadHotelsFailure(err as Error))
  }
}

function* loadVisitCategoriesRequest(parameters: any) {
  try {
    const { data, status }: AxiosResponse<IVisitCategory[]> = yield call(
      getVisitCategories,
      parameters.payload as IVisitTypeCategoryParamsQuery
    )
    if (status === 200) {
      yield put(loadVisitCategoriesSuccess(data))
    }
  } catch (err) {
    yield put(loadVisitCategoriesFailure(err as Error))
  }
}

function* loadDestinationsRequest() {
  try {
    const { data, status }: AxiosResponse<IDestination[]> = yield call(getDestinations)
    if (status === 200) {
      yield put(loadDestinationsSuccess(data))
    }
  } catch (err) {
    yield put(loadDestinationsFailure(err as Error))
  }
}

function* loadLowestPriceRequest(parameters: any) {
  try {
    const { data, status }: AxiosResponse<IPrice> = yield call(
      getLowestPrice,
      parameters.payload as URLSearchParams
    )
    if (status === 200) {
      yield put(loadLowestPriceSuccess(data))
    }
  } catch (err) {
    yield put(loadLowestPriceFailure(err as Error))
  }
}

function* loadPriceRequest(parameters: any) {
  try {
    const { data, status }: AxiosResponse<IPrice> = yield call(
      getPrice,
      parameters.payload as URLSearchParams
    )
    if (status === 200) {
      yield put(loadPriceSuccess(data))
    }
  } catch (err) {
    yield put(loadPriceFailure(err as Error))
  }
}

function* loadLoyaltyProgram() {
  try {
    const { data, status }: AxiosResponse<ILoyaltyProgram[]> = yield call(getLoyaltyProgram)
    if (status === 200) {
      yield put(loadLoyaltyProgramSuccess(data))
    }
  } catch (err) {
    yield put(loadLoyaltyProgramFailure(err as Error))
  }
}

const prepareGuestDataForBookingRequest = (
  guestsData: GuestFormData[],
  servicesFormData: ServiceFormData
) => {
  return guestsData.map((guest, index) => {
    // A guest is optional for a demand, but in this case, we must provide all mandatory data as placeholders.
    const guestDefined = isGuestDefined(guest)

    return {
      ...(!guestDefined
        ? {
            birth_date: format(
              new Date(Math.floor(new Date().getTime() / 1000)),
              DATE_FORMAT_YYYY_MM_DD
            ),
            first_name: 'Guest',
            gender: 'male',
            last_name: `${index + 1}`,
          }
        : {
            birth_date: format(new Date(guest.birth_date!), DATE_FORMAT_YYYY_MM_DD),
            first_name: guest.first_name,
            gender: guest.gender,
            last_name: guest.last_name,
          }),
      loyalty_program_code: servicesFormData.loyaltyPrograms?.[index]?.code || '',
      loyalty_program_id: servicesFormData.loyaltyPrograms?.[index]?.id || '',
    }
  })
}

function* sendBookingRequest() {
  yield put(setLoader(Loaders.BOOKING))
  const currency: ICurrency = yield select(selectors.getCurrency)
  const language: ILanguage = yield select(selectors.getLanguage)
  const demand: boolean = yield select(selectors.isDemand)
  const guestsFormData: GuestsFormData = yield select(selectors.getGuestsData)
  const hotel: IHotel = yield select(selectors.getHotel)
  const personalFormData: PersonalFormData = yield select(selectors.getPersonalData)
  const roomType: IRoomType = yield select(selectors.getRoomType)
  const servicesFormData: ServiceFormData = yield select(selectors.getServicesData)
  const summaryFormData: SummaryFormData = yield select(selectors.getSummaryData)
  const travelDate: ITravelDate = yield select(selectors.getTravelDate)
  const visitType: IVisitType = yield select(selectors.getVisitType)
  const affilUserName: string | null = yield select(selectors.getAffiliateUsername)

  const values: IBookingRequest = {
    billed_to: {
      address: !demand
        ? {
            city: personalFormData.city,
            country: personalFormData.country,
            house_number: personalFormData.house_number,
            street: personalFormData.street,
            zip_code: personalFormData.zip_code,
          }
        : undefined,
      consent_marketing: summaryFormData.marketing,
      email: personalFormData.email,
      first_name: personalFormData.first_name,
      gender: personalFormData.gender,
      last_name: personalFormData.last_name,
      phone: personalFormData.phone,
    },
    binding_booking: !demand,
    currency,
    customer_note: servicesFormData.note || null,
    date_from: travelDate.from ? format(travelDate.from, DATE_FORMAT_YYYY_MM_DD) : '',
    date_to: travelDate.to ? format(travelDate.to, DATE_FORMAT_YYYY_MM_DD) : '',
    guests: prepareGuestDataForBookingRequest(guestsFormData.guests, servicesFormData) as IGuest[],
    hotel_id: parseInt(hotel.id as string),
    room_type_id: parseInt(roomType.id as string),
    visit_type_id: parseInt(visitType.id as string),
    ...(affilUserName && {
      affilUserName,
    }),
  }

  try {
    const { data, status }: AxiosResponse<IBookingResponse> = yield call(
      sendBooking,
      values,
      language
    )
    if (status === 200) {
      yield put(sendBookingSuccess(data))
      redirectTo(PathEnum.STEP_6, {}).then(() => put(setLoader(Loaders.BOOKING)))
    }
  } catch (err) {
    yield put(setLoader(Loaders.BOOKING))
    const errorModifications = {
      level: MESSAGE_LEVELS.FATAL,
      message: `URGENT - Booking of visit didn't proceed.`,
      name: 'URGENT',
    }
    const customizedError = customizeError(err as Error, errorModifications)
    yield put(sendBookingFailure(customizedError as Error))
  }
}

function* setTravelDateRequest({ payload }: IAction<ITravelDate>) {
  if (payload && payload?.from instanceof Date && payload?.to instanceof Date) {
    const hotel: IHotel = yield select(selectors.getHotel)
    const roomType: IRoomType = yield select(selectors.getRoomType)
    const visitType: IVisitType = yield select(selectors.getVisitType)

    try {
      const { data, status }: AxiosResponse<IDate[]> = yield call(getDates, {
        date_from: format(payload.from, DATE_FORMAT_YYYY_MM_DD),
        date_to: format(payload.to, DATE_FORMAT_YYYY_MM_DD),
        hotel_id: hotel?.id || undefined,
        room_type_id: roomType?.id || undefined,
        visit_type_id: visitType?.id || undefined,
      })
      if (status === 200 && data.filter((item) => item.availability === 'ON_DEMAND').length > 0) {
        yield put(setOnlyAvailableDemand(true))
      } else {
        yield put(setOnlyAvailableDemand(false))
      }
    } catch {
      yield put(setOnlyAvailableDemand(false))
    }
  }
}

function* setCalendarTravelDateRequest({ payload }: IAction<ITravelDate>) {
  const roomType: IRoomType = yield select(selectors.getRoomType)
  const visitType: IVisitType = yield select(selectors.getVisitType)
  const hotel: IHotel = yield select(selectors.getHotel)

  yield put({ type: ActionType.LOAD_CALENDAR_PRICE_REQUEST })

  if (Boolean(visitType?.id && hotel?.id && roomType?.id && payload?.from && payload?.to)) {
    const travelDate = payload as ITravelDate

    const query = new URLSearchParams()
    query.append('visit_type_id', visitType.id.toString())
    query.append('room_type_id', roomType.id.toString())
    query.append('date_from', format(travelDate.from as Date, DATE_FORMAT_YYYY_MM_DD))
    query.append('date_to', format(travelDate.to as Date, DATE_FORMAT_YYYY_MM_DD))

    try {
      const { data, status }: AxiosResponse<IPrice> = yield call(getPrice, query)
      if (status === 200) {
        yield put(loadCalendarPriceSuccess(data))
      }
    } catch (err) {
      yield put(loadCalendarPriceFailure(err as Error))
    }
  }
}

function* validateCalendarDatesRequest(
  action: IAction<{ allAvailableDates: IDate[]; initial?: boolean }>
) {
  const { allAvailableDates, initial = false } = action.payload || {}

  if (allAvailableDates) {
    const travelDate: ITravelDate = yield select(selectors.getTravelDate)

    const { from, to } = travelDate

    let shouldResetCalendar = false

    if (isDate(from) && isDate(to)) {
      const fromCalendarTravelDateFormat = format(from as Date, DATE_FORMAT_YYYY_MM_DD)
      const toCalendarTravelDateFormat = format(to as Date, DATE_FORMAT_YYYY_MM_DD)

      const findValidDateFromAvailable = allAvailableDates.find(
        (item) => item.date === fromCalendarTravelDateFormat
      )

      if (findValidDateFromAvailable) {
        const roomType: IRoomType = yield select(selectors.getRoomType)
        const visitType: IVisitType = yield select(selectors.getVisitType)
        const hotel: IHotel = yield select(selectors.getHotel)

        const { data, status }: AxiosResponse<IDateCheckout[]> = yield call(getDatesCheckout, {
          hotel_id: hotel?.id || undefined,
          room_type_id: roomType?.id || undefined,
          start_date: fromCalendarTravelDateFormat,
          visit_type_id: visitType?.id || undefined,
        })
        if (status !== 200 || !data.find((item) => item.date === toCalendarTravelDateFormat)) {
          shouldResetCalendar = true
        }
      } else {
        shouldResetCalendar = true
      }
    } else if (!initial) {
      shouldResetCalendar = true
    }

    if (shouldResetCalendar) {
      yield put(resetCalendar())
    }
  }
}

function* loadInitDataRequest({ payload }: IAction<IRouteQueryParams>) {
  if (payload && Object.keys(payload).length > 0) {
    try {
      const language = getLangIso(payload?.lang || 'cs_CZ')

      const { data: destinationsData, status: destinationsStatus }: AxiosResponse<IDestination[]> =
        yield call(getDestinations)

      const bookingConfigDestinationName = getBookingConfigDestinationQueryParameter(
        payload?.destination || null,
        language,
        destinationsData
      )

      const bookingConfigQueryParams = {
        date_from: payload?.visitStart || null,
        date_to: payload?.visitEnd || null,
        destination_name: bookingConfigDestinationName,
        guest: payload?.guest ? +payload.guest : null,
        hotel_name: payload?.hotel || null,
        lang: payload?.lang || null,
        room_type_category: payload?.roomType || null,
        visit_type_name: payload?.visitType || null,
      }

      const { data: bookingConfigData, status }: AxiosResponse<IInitDataResponse> = yield call(
        getBookingConfiguration,
        sanitizeBookingParams(bookingConfigQueryParams)
      )

      if (status === 200 && destinationsStatus === 200) {
        const travelDate: ITravelDate = {
          from: bookingConfigData.date_from
            ? parseDateString(bookingConfigData.date_from as string)
            : null,
          to: bookingConfigData.date_to
            ? parseDateString(bookingConfigData.date_to as string)
            : null,
        }

        // If guest is not defined but room_type with beds is defined => fill guest from beds
        const guest =
          getValidGuestNumberOrNull(bookingConfigQueryParams.guest) ||
          (bookingConfigData.room_type?.beds as number) ||
          null

        // If the destination from query parameters is undefined or invalid, check if a default destination is specified in the .env file and use that; otherwise, return null.
        const destination = getValidDestinationOrNull(destinationsData, bookingConfigData)

        yield put(
          loadInitDataSuccess({
            ...bookingConfigData,
            date_from: travelDate.from,
            date_to: travelDate.to,
            destination,
            destinations: destinationsData,
            guest,
            language,
          })
        )

        // update with guest and destination obtained above
        yield put(
          setRouteQueryParams({
            ...payload,
            destination: destination ? getLang(destination.name, language) : null,
            guest: guest?.toString() || null,
          })
        )

        yield call(setCalendarTravelDateRequest, {
          payload: travelDate,
          type: ActionType.SET_CALENDAR_TRAVEL_DATE,
        })
        yield call(setTravelDateRequest, {
          payload: travelDate,
          type: ActionType.SET_TRAVEL_DATE,
        })

        yield put(setAppInitialized(true))
      }
    } catch (err) {
      yield put(loadInitDataFailure(err as Error))
    }
  }
}

function* failureNotification({ error: APIError, payload, type }: IAction) {
  if (/^.*_FAILURE/.test(type)) {
    if (APIError) alertSentryOnAPIError(APIError)

    let isNetworkError = false
    const error: Error = (payload as Error) || APIError || new Error('Unknown error')

    if (error instanceof AxiosError && error.code === 'ERR_NETWORK') {
      isNetworkError = true
    }

    yield put(
      addNotification({
        closable: !isNetworkError,
        isNetworkError,
        message: error.message,
        timestamp: new Date().getTime(),
      })
    )
  }
}

function* rootSaga(): Generator {
  yield all([
    takeLatest(ActionType.LOAD_DATES_CHECKOUT_REQUEST, loadDatesCheckoutRequest),
    takeLatest(ActionType.LOAD_DATES_REQUEST, loadDatesRequest),
    takeLatest(ActionType.LOAD_HOTELS_REQUEST, loadHotelsRequest),
    takeLatest(ActionType.LOAD_HOTEL_REQUEST, loadHotelRequest),
    takeLatest(ActionType.LOAD_LOWEST_PRICE_REQUEST, loadLowestPriceRequest),
    takeLatest(ActionType.LOAD_LOYALTY_PROGRAM_REQUEST, loadLoyaltyProgram),
    takeLatest(ActionType.LOAD_PRICE_REQUEST, loadPriceRequest),
    takeLatest(ActionType.LOAD_DESTINATIONS_REQUEST, loadDestinationsRequest),
    takeLatest(ActionType.LOAD_VISIT_CATEGORIES_REQUEST, loadVisitCategoriesRequest),
    takeLatest(ActionType.SEND_BOOKING_REQUEST, sendBookingRequest),
    takeLatest(ActionType.SET_CALENDAR_TRAVEL_DATE, setCalendarTravelDateRequest),
    takeLatest(ActionType.LOAD_INIT_DATA_REQUEST, loadInitDataRequest),
    takeLatest(ActionType.SET_TRAVEL_DATE, setTravelDateRequest),
    takeLatest(ActionType.VALIDATE_CALENDAR, validateCalendarDatesRequest),
    takeEvery('*', failureNotification),
  ])
}

export default rootSaga
