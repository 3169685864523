import * as React from 'react'
import { ILanguage } from '@interfaces/data'
import { useAppContext } from './AppContext'
import styles from './SelectLanguage.module.scss'

const SelectLanguage: React.FunctionComponent = () => {
  const { language, onChangeLanguage } = useAppContext()
  const id = React.useId()

  const handleChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeLanguage(target.value as ILanguage, {})
  }

  return (
    <div className={styles.root}>
      <select id={id} onChange={handleChange} value={language}>
        <option value="cs">CZ</option>
        <option value="de">DE</option>
        <option value="en">EN</option>
      </select>
    </div>
  )
}

export default SelectLanguage
