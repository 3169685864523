import '@styles/index.scss'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'
import * as React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import AppContextProvider from '@components/AppContext'
import IntlProvider from '@components/IntlProvider'
import Layout from '@components/Layout'
import Notifications from '@components/Notifications'
import { RouterReady } from '@components/RouterReady'
import { persistor, wrapper } from '@redux/store'

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const MyApp: NextPage<AppProps> = ({ Component, ...rest }: AppProps) => {
  const { props, store } = wrapper.useWrappedStore(rest)

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider>
          <AppContextProvider>
            <RouterReady>
              <Layout>
                <Component {...props.pageProps} />
              </Layout>
            </RouterReady>
            <Notifications />
          </AppContextProvider>
        </IntlProvider>
      </PersistGate>
    </Provider>
  )
}

export default MyApp
