import * as React from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import { ILanguage } from '@interfaces/data'
import { initialState } from '@redux/reducer'
import * as selectors from '@redux/selectors'
import messagesCs from '../../compiled-lang/cs.json'
import messagesDe from '../../compiled-lang/de.json'
import messagesEn from '../../compiled-lang/en.json'
import messagesRu from '../../compiled-lang/ru.json'

const getMessages = (language: ILanguage) => {
  if (language === 'cs') {
    return messagesCs
  }
  if (language === 'de') {
    return messagesDe
  }
  if (language === 'ru') {
    return messagesRu
  }
  return messagesEn
}

const IntlProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const language = useSelector(selectors.getLanguage)
  return (
    <ReactIntlProvider
      defaultLocale={initialState.language}
      key={language}
      locale={language}
      messages={getMessages(language)}
    >
      {children}
    </ReactIntlProvider>
  )
}

export default IntlProvider
