import clsx from 'classnames'
import * as React from 'react'
import { useSelector } from 'react-redux'
import ScreenLoader from '@components/ScreenLoader'
import * as selectors from '@redux/selectors'
import Container from './Container'
import styles from './Content.module.scss'

const Content: React.FC<React.PropsWithChildren> = ({ children }) => {
  const step = useSelector(selectors.getStep)
  const languageInitialized = useSelector(selectors.getLanguageInitialized)

  return (
    <main
      className={clsx(styles.root, {
        [styles.rootBackgroundGradient]: step > 1 && step < 5,
      })}
    >
      <ScreenLoader isLoading={!languageInitialized}>
        <Container>{children}</Container>
      </ScreenLoader>
    </main>
  )
}

export default Content
