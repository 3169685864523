import * as React from 'react'
import Typography from '../Typography'
import CloseButton from './CloseButton'
import styles from './Notification.module.scss'

type NotificationProps = {
  actionButton?: React.ReactNode
  message: React.ReactNode
  onClose?: () => void
}

const Notification: React.FunctionComponent<NotificationProps> = ({
  actionButton = undefined,
  message,
  onClose = undefined,
}) => (
  <div className={styles.root}>
    <Typography>{message}</Typography>
    {onClose && <CloseButton onClose={onClose} />}
    {actionButton && actionButton}
  </div>
)

export default Notification
