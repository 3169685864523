import { useRouter } from 'next/router'
import { ReactNode } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { resetApplication } from '@redux/actions'
import { basicSelector } from '../helpers'

interface CompanyHeaderLinkWrapperProps {
  children: ReactNode
  link: string
}

export const CompanyHeaderLinkWrapper = ({ children, link }: CompanyHeaderLinkWrapperProps) => {
  const { formatMessage } = useIntl()
  const router = useRouter()
  const dispatch = useDispatch()

  const { isDataFilled } = useSelector(basicSelector)

  const handleOnCompanyHeaderLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    if (!isDataFilled) {
      router.push(link)
    } else {
      const message = formatMessage({
        defaultMessage:
          'Are you sure you want to leave? If you do so, your data will be reset to factory settings and you will have to continue again.',
        description: 'Alert message for leave the booking form',
      })

      if (confirm(message)) {
        dispatch(resetApplication())
        router.push(link)
      }
    }
  }

  return (
    <a
      onClick={handleOnCompanyHeaderLinkClick}
      rel="noopener noreferrer"
      style={{ cursor: 'pointer' }}
    >
      {children}
    </a>
  )
}
