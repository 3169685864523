import * as React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ICurrency, ILanguage, IRouteQueryParams } from '@interfaces/data'
import { resetApplication, setLanguage } from '@redux/actions'
import { initialState } from '@redux/reducer'
import { updateLocaleStorageValue } from '@utils/localStorage'
import { basicSelector, PathEnum, redirectTo, useGoogleTagManager } from '../helpers'

export interface IAppContext {
  currency: ICurrency
  isFetching: boolean
  language: ILanguage
  onChangeLanguage: (lang: ILanguage, routeQueryParams: IRouteQueryParams) => void
}

export const AppContext = React.createContext<IAppContext>({
  currency: initialState.currency,
  isFetching: false,
  language: initialState.language,
  onChangeLanguage: () => {},
})

export const useAppContext = () => React.useContext<IAppContext>(AppContext)

const AppContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  useGoogleTagManager()

  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { currency, isDataFilled, isFetching, language } = useSelector(basicSelector)

  const onChangeLanguage = React.useCallback(
    (lang: ILanguage, routeQuery: IRouteQueryParams) => {
      const condition = isDataFilled ? confirm : () => true
      const message = formatMessage({
        defaultMessage:
          'Are you sure you want to switch language? If you do so, your data will be reset to factory settings and you will have to continue again.',
        description: 'Alert message for switch language',
      })

      if (condition(message)) {
        dispatch(setLanguage(lang))
        dispatch(resetApplication())
        updateLocaleStorageValue('lang', lang)
        redirectTo(PathEnum.STEP_1, routeQuery, lang)
      }
    },
    [isDataFilled, formatMessage, dispatch]
  )

  const value = React.useMemo<IAppContext>(() => {
    return {
      currency,
      isFetching,
      language,
      onChangeLanguage,
    }
  }, [currency, isFetching, language, onChangeLanguage])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export default AppContextProvider
