import { getCurrencyFromLanguage, getLangIso, getValidGuestNumberOrNull } from '../helpers'
import {
  AccordionEnum,
  ActionType,
  AppState,
  CalendarModeEnum,
  CalendarStateEnum,
  CurrencyEnum,
  GuestsFormData,
  IAction,
  ICurrency,
  IDate,
  IDateCheckout,
  IDateWithCache,
  IDestination,
  IHotel,
  IInitDataSuccessPayload,
  ILanguage,
  ILoaders,
  ILoyaltyProgram,
  INotification,
  IPrice,
  IRoomType,
  IRouteQueryParams,
  ITravelDate,
  IValidationState,
  IVisitCategory,
  IVisitType,
  LanguageEnum,
  PaymentEnum,
  PersonalFormData,
  ServiceFormData,
  SummaryFormData,
} from './interfaces'

export const initialState: AppState = {
  accordion: null,
  affiliate: {
    affilUserName: null,
  },
  appInitialized: false,
  appInitializedAndValidated: false,
  calendar: {
    checkout: [],
    data: [],
    date: new Date(),
    datesCache: {},
    isDidInvalid: false,
    isFetching: false,
    mode: CalendarModeEnum.FROM_DATE,
    price: null,
    state: CalendarStateEnum.INITIAL,
    travelDate: {
      from: null,
      to: null,
    },
  },
  currency: CurrencyEnum.EUR,
  demand: false,
  destination: null,
  destinations: null,
  guest: null,
  guestsData: {
    guests: [],
  },
  hotel: null,
  hotelDialog: {
    data: null,
    isDidInvalid: false,
    isFetching: false,
  },
  hotels: [],
  images: {
    loaded: 0,
    loading: 0,
  },
  isFetching: false,
  isFormFilled: false,
  language: LanguageEnum.CS,
  languageInitialized: false,
  loaders: {
    booking: false,
  },
  lowestPrice: null,
  loyaltyProgram: [],
  notifications: [],
  onlyDemand: false,
  personalData: {
    birth_date: '',
    city: '',
    country: '',
    email: '',
    first_name: '',
    gender: null,
    house_number: '',
    last_name: '',
    phone: '',
    street: '',
    zip_code: '',
  },
  price: null,
  roomType: null,
  roomTypeDialog: {
    data: null,
    isDidInvalid: false,
    isFetching: false,
  },
  routeQueryParams: {},
  routerInitialized: false,
  servicesData: {
    loyaltyPrograms: [],
    note: null,
  },
  stepper: {
    step: 1,
    totalStep: 5,
  },
  summaryData: {
    agree: false,
    marketing: false,
    payment: PaymentEnum.Bank,
  },
  travelDate: {
    from: null,
    to: null,
  },
  validationState: null,
  visitCategories: [],
  visitType: null,
  visitTypeCategory: null,
  visitTypeDialog: {
    data: null,
    isDidInvalid: false,
    isFetching: false,
  },
}

const reducer = (state = initialState, action: IAction): AppState => {
  switch (action.type) {
    case ActionType.LOAD_DATES_REQUEST: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          data: [],
          isFetching: true,
        },
      }
    }

    case ActionType.LOAD_DATES_SUCCESS: {
      const payload = action.payload as IDateWithCache

      const dateArrayFromResponse = payload.dates as IDate[]
      // const dateArrayInStore = state.calendar.data

      const dateRangesCacheInStore = state.calendar.datesCache
      const requestedDateRanges = payload.datesCache as Record<string, IDate[]>

      return {
        ...state,
        calendar: {
          ...state.calendar,
          data: dateArrayFromResponse,
          // data: mergeDateArrays(dateArrayInStore, dateArrayFromResponse),
          datesCache: {
            ...dateRangesCacheInStore,
            ...requestedDateRanges,
          },
          isDidInvalid: false,
          isFetching: false,
        },
      }
    }

    case ActionType.LOAD_DATES_FAILURE:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          data: [],
          isFetching: false,
        },
      }

    case ActionType.LOAD_DATES_CHECKOUT_REQUEST:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          checkout: [],
          isFetching: true,
        },
      }

    case ActionType.LOAD_DATES_CHECKOUT_SUCCESS:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          checkout: action.payload as IDateCheckout[],
          isFetching: false,
        },
      }

    case ActionType.LOAD_DATES_CHECKOUT_FAILURE:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          checkout: [],
          isFetching: false,
        },
      }

    case ActionType.LOAD_VISIT_CATEGORIES_REQUEST:
      return {
        ...state,
        visitCategories: [],
      }

    case ActionType.LOAD_VISIT_CATEGORIES_SUCCESS:
      return {
        ...state,
        visitCategories: action.payload as IVisitCategory[],
      }

    case ActionType.LOAD_DESTINATIONS_REQUEST:
      return {
        ...state,
        destinations: [],
      }

    case ActionType.LOAD_DESTINATIONS_SUCCESS:
      return {
        ...state,
        destinations: action.payload as IDestination[],
      }

    case ActionType.LOAD_HOTELS_REQUEST:
    case ActionType.LOAD_HOTELS_FAILURE:
      return {
        ...state,
        hotels: [],
      }

    case ActionType.LOAD_HOTELS_SUCCESS:
      return {
        ...state,
        hotels: (action.payload as IHotel[]).map((hotel) => {
          return {
            ...hotel,
            id: Number(hotel.id),
          }
        }),
      }

    case ActionType.SET_STEP:
      return {
        ...state,
        stepper: {
          ...state.stepper,
          step: action.payload as number,
        },
      }

    case ActionType.CHANGE_GUEST:
      const guest = getValidGuestNumberOrNull(action.payload as number)
      return {
        ...state,
        calendar: {
          ...state.calendar,
          datesCache: {},
        },
        guest,
      }

    case ActionType.CHANGE_VALIDATION_STATE:
      const validationState = action.payload as IValidationState

      return { ...state, validationState }

    case ActionType.CHANGE_VISIT_TYPE:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          datesCache: {},
          state: CalendarStateEnum.INITIAL,
          travelDate: {
            from: null,
            to: null,
          },
        },
        travelDate: {
          from: null,
          to: null,
        },
        visitType: action.payload as IVisitType | null,
      }

    case ActionType.CHANGE_DESTINATION:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          datesCache: {},
        },
        destination: action.payload as IDestination | null,
      }

    case ActionType.CHANGE_VISIT_TYPE_CATEGORY:
      return {
        ...state,
        calendar: {
          ...state.calendar,
          datesCache: {},
        },
        visitTypeCategory: action.payload as IVisitCategory | null,
      }

    case ActionType.CHANGE_ROOM_TYPE:
      const roomType = action.payload as IRoomType | null
      if (state.guest === null) {
        return {
          ...state,
          calendar: {
            ...state.calendar,
            datesCache: {},
            state: CalendarStateEnum.INITIAL,
            travelDate: {
              from: null,
              to: null,
            },
          },
          guest: roomType?.beds || null,
          roomType,
          travelDate: {
            from: null,
            to: null,
          },
        }
      } else {
        return {
          ...state,
          calendar: {
            ...state.calendar,
            datesCache: {},
            state: CalendarStateEnum.INITIAL,
            travelDate: {
              from: null,
              to: null,
            },
          },
          roomType,
          travelDate: {
            from: null,
            to: null,
          },
        }
      }

    case ActionType.CHANGE_HOTEL:
      const hotel = action.payload as IHotel | null
      return {
        ...state,
        calendar: {
          ...state.calendar,
          datesCache: {},
          state: CalendarStateEnum.INITIAL,
          travelDate: {
            from: null,
            to: null,
          },
        },
        hotel: hotel ? { ...hotel, id: +hotel.id } : null,
        roomType: null,
        travelDate: {
          from: null,
          to: null,
        },
      }

    case ActionType.CHANGE_CALENDAR_DATE: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          date: action.payload as Date,
          state: CalendarStateEnum.SET,
        },
      }
    }

    case ActionType.SET_DEMAND:
      return {
        ...state,
        demand: Boolean(action.payload),
      }

    case ActionType.SET_AVAILABLE_DEMAND:
      return {
        ...state,
        onlyDemand: Boolean(action.payload),
      }

    case ActionType.SET_CURRENCY:
      return {
        ...state,
        currency: action.payload as ICurrency,
      }

    case ActionType.SET_TRAVEL_DATE:
      return {
        ...state,
        travelDate: action.payload as ITravelDate,
      }

    case ActionType.SET_LANGUAGE:
      const language = action.payload as ILanguage
      return {
        ...state,
        currency: getCurrencyFromLanguage(language),
        language,
      }

    case ActionType.SAVE_PERSONAL_DATA:
      return {
        ...state,
        personalData: action.payload as PersonalFormData,
      }

    case ActionType.SAVE_GUESTS_DATA:
      return {
        ...state,
        guestsData: action.payload as GuestsFormData,
      }

    case ActionType.SAVE_SERVICES_DATA:
      return {
        ...state,
        servicesData: action.payload as ServiceFormData,
      }

    case ActionType.SAVE_SUMMARY_DATA:
      return {
        ...state,
        summaryData: action.payload as SummaryFormData,
      }

    case ActionType.LOAD_HOTEL_REQUEST:
      return {
        ...state,
        hotelDialog: {
          ...state.hotelDialog,
          isFetching: true,
        },
      }

    case ActionType.LOAD_HOTEL_SUCCESS:
      return {
        ...state,
        hotelDialog: {
          ...state.hotelDialog,
          data: action.payload as IHotel | null,
          isFetching: false,
        },
      }

    case ActionType.LOAD_HOTEL_FAILURE:
      return {
        ...state,
        hotelDialog: {
          ...state.hotelDialog,
          data: null,
          isDidInvalid: false,
          isFetching: false,
        },
      }

    case ActionType.SET_HOTEL_DIALOG_DATA: {
      return {
        ...state,
        hotelDialog: {
          ...state.hotelDialog,
          data: action.payload as IHotel | null,
        },
      }
    }

    case ActionType.LOAD_LOYALTY_PROGRAM_REQUEST:
    case ActionType.LOAD_LOYALTY_PROGRAM_FAILURE:
      return {
        ...state,
        loyaltyProgram: [],
      }

    case ActionType.LOAD_LOYALTY_PROGRAM_SUCCESS: {
      return {
        ...state,
        loyaltyProgram: action.payload as ILoyaltyProgram[],
      }
    }

    case ActionType.SET_VISIT_TYPE_DIALOG_DATA: {
      return {
        ...state,
        visitTypeDialog: {
          ...state.visitTypeDialog,
          data: action.payload as IVisitType | null,
        },
      }
    }

    case ActionType.SET_ROOM_TYPE_DIALOG_DATA: {
      return {
        ...state,
        roomTypeDialog: {
          ...state.roomTypeDialog,
          data: action.payload as IRoomType | null,
        },
      }
    }

    case ActionType.CHANGE_ACCORDION: {
      return {
        ...state,
        accordion: action.payload as AccordionEnum | null,
        images: { ...state.images, loaded: 0 },
      }
    }

    case ActionType.LOAD_LOWEST_PRICE_SUCCESS: {
      return {
        ...state,
        lowestPrice: action.payload as IPrice,
      }
    }

    case ActionType.LOAD_LOWEST_PRICE_FAILURE: {
      return {
        ...state,
        lowestPrice: null,
      }
    }

    case ActionType.LOAD_PRICE_SUCCESS: {
      return {
        ...state,
        price: action.payload as IPrice,
      }
    }

    case ActionType.LOAD_PRICE_FAILURE: {
      return {
        ...state,
        price: null,
      }
    }

    case ActionType.LOAD_CALENDAR_PRICE_SUCCESS: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          price: action.payload as IPrice,
        },
      }
    }

    case ActionType.LOAD_CALENDAR_PRICE_FAILURE: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          price: null,
        },
      }
    }

    case ActionType.SET_CALENDAR_MODE: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          mode: action.payload as CalendarModeEnum,
        },
      }
    }

    case ActionType.SET_CALENDAR_TRAVEL_DATE: {
      return {
        ...state,
        calendar: {
          ...state.calendar,
          price: initialState.calendar.price,
          travelDate: action.payload as ITravelDate,
        },
      }
    }

    case ActionType.RESET_APP: {
      return {
        ...initialState,
        affiliate: state.affiliate,
        appInitialized: state.appInitialized,
        appInitializedAndValidated: state.appInitializedAndValidated,
        currency: state.currency,
        destinations: state.destinations,
        language: state.language,
        languageInitialized: state.languageInitialized,
      }
    }

    case ActionType.RESET_CALENDAR: {
      return {
        ...state,
        calendar: initialState.calendar,
        travelDate: initialState.travelDate,
      }
    }

    case ActionType.ADD_NOTIFICATION: {
      return {
        ...state,
        notifications: [...state.notifications, action.payload as INotification],
      }
    }

    case ActionType.REMOVE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification !== (action.payload as INotification)
        ),
      }
    }

    case ActionType.LOAD_INIT_DATA_REQUEST: {
      const result = action.payload as IRouteQueryParams

      return {
        ...state,
        isFetching: true,
        language: result?.lang ? getLangIso(result.lang) : state.language,
      }
    }

    case ActionType.SET_ROUTE_QUERY_PARAMS: {
      const result = action.payload as IRouteQueryParams
      return {
        ...state,
        routeQueryParams: result,
      }
    }

    case ActionType.LOAD_INIT_DATA_SUCCESS: {
      const result = action.payload as IInitDataSuccessPayload

      const language = result.language || state.language

      const travelDate = {
        from: result.date_from,
        to: result.date_to,
      }

      return <AppState>{
        ...state,
        calendar: {
          ...state.calendar,
          price: initialState.calendar.price,
          travelDate,
        },
        currency: getCurrencyFromLanguage(language),
        demand: result?.availability ? result.availability === 'ON_DEMAND' : state.demand,
        destination: result.destination
          ? { ...result.destination, id: +result.destination.id }
          : null,
        destinations: result?.destinations ?? [],
        guest: result.guest,
        hotel: result.hotel ? { ...result.hotel, id: +result.hotel.id } : null,
        isFetching: false,
        language,
        price: result.price || null,
        roomType: result.room_type || null,
        travelDate,
        visitType: result.visit_type || null,
      }
    }

    case ActionType.LOAD_INIT_DATA_FAILURE: {
      return {
        ...state,
        isFetching: false,
      }
    }

    case ActionType.SET_IS_FORM_FILLED: {
      return {
        ...state,
        isFormFilled: action.payload as boolean,
      }
    }

    case ActionType.SET_LOADER: {
      const loader = action.payload as keyof ILoaders
      return {
        ...state,
        loaders: {
          ...state.loaders,
          [loader]: !state.loaders[loader],
        },
      }
    }

    case ActionType.SET_LANGUAGE_INITIALIZED: {
      return {
        ...state,
        languageInitialized: action.payload as boolean,
      }
    }

    case ActionType.SET_APP_INITIALIZED: {
      return {
        ...state,
        appInitialized: action.payload as boolean,
      }
    }

    case ActionType.SET_APP_INITIALIZED_AND_VALIDATED: {
      return {
        ...state,
        appInitializedAndValidated: action.payload as boolean,
      }
    }

    case ActionType.SET_ROUTER_INITIALIZED: {
      return {
        ...state,
        routerInitialized: action.payload as boolean,
      }
    }

    case ActionType.SET_AFFILIATE_USERNAME: {
      return {
        ...state,
        affiliate: {
          affilUserName: action.payload as string,
        },
      }
    }

    case ActionType.SET_LOADING_IMAGES: {
      const stateImages = state.images
      return {
        ...state,
        images: { ...stateImages, loading: action.payload as number },
      }
    }

    case ActionType.ADD_LOADED_IMAGE: {
      const stateImages = state.images
      return {
        ...state,
        images: { ...stateImages, loaded: ++stateImages.loaded },
      }
    }

    default:
      return state
  }
}

export default reducer
